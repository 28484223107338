<template>
  <div>
    <!--begin::customer-->
    <div>
      <h3 class="card-label">
        {{ $t('subscriptions.subscriptions') }}
        <span class="text-muted pt-2 font-size-sm d-block"></span>
      </h3>


      <b-tabs content-class="mt-3" class="nav-custom-link">
        <b-tab :title="$t('subscriptions.basic_information')" @click="toggleTab('basic_information')">

          <div class="row">
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('subscriptions.subscription_name') }}<span class="text-danger">*</span></label>
                    <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.name[0] }}
                                    </span>

                  </div>
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('subscriptions.customer') }}<span class="text-danger">*</span></label>
                    <div class="form-group row mb-1">
                      <div class="col-sm-8">
                        <!-- <select name="" id="customer_id" v-model="data.customer_id" class="custom-select" :class="validation && validation.customer_id ? 'is-invalid' : ''">
                            <option v-for="row in customers" :value="row.id" :key="row.id">{{ row.fullname }}</option>
                        </select> -->
                        <multiselect v-model="customer" :placeholder="$t('subscriptions.customer')"
                                     label="name" track-by="id"
                                     :options="customers"
                                     :multiple="false"
                                     :taggable="false"
                                     :show-labels="false"
                                     :show-no-options="false"
                                     :show-no-results="false"
                                     @search-change="getCustomers($event)">
                        </multiselect>
                        <span class="form-text text-muted" style="text-align:end">{{ $t('type_at_least_three_letters') + ' ' + $t('subscriptions.customer') }}.</span>
                      </div>

                      <div class="col-sm-4">
                        <button class="btn btn-primary" @click="showModal">{{ $t('add_new') }}</button>
                      </div>
                    </div>
                    <span v-if="validation && validation.customer_id" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.customer_id[0] }}
                                    </span>
                  </div>
                </div>
              </div>
              <div class="card card-custom mt-10">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('subscriptions.shipping_details') }}</label>
                    <select name="" id="shipping_details" v-model="data.shipping_details" class="custom-select" :class="validation && validation.shipping_details ? 'is-invalid' : ''">
                      <option v-for="row in shipping_details_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                    </select>
                    <span v-if="validation && validation.shipping_details" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.shipping_details[0] }}
                            </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('subscriptions.shipping_fees') }}</label>
                    <input type="number" v-model="data.shipping_fees" class="form-control" :class="validation && validation.shipping_fees ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.shipping_fees" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.shipping_fees[0] }}
                            </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('subscriptions.discount_type') }}</label>
                    <select name="" id="discount_types" v-model="data.discount_type" class="custom-select" :class="validation && validation.discount_type ? 'is-invalid' : ''">
                      <option v-for="row in discount_types" :value="row.id" :key="row.id">{{ row.name }}</option>
                    </select>
                    <span v-if="validation && validation.discount_type" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.discount_type[0] }}
                                </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('subscriptions.discount_value') }}</label>
                    <input type="number" v-model="data.discount_value" class="form-control" :class="validation && validation.discount_value ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.discount_value" class="fv-plugins-message-container invalid-feedback">
                                    {{ validation.discount_value[0] }}
                                </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <b-form-checkbox size="lg" v-model="data.send_copy_flag" name="check-button" switch>{{ $t('subscriptions.send_copy_flag') }}</b-form-checkbox>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <b-form-checkbox size="lg" v-model="data.display_dates_flag" name="check-button" switch>{{ $t('subscriptions.display_dates_flag') }}</b-form-checkbox>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <b-form-checkbox size="lg" v-model="data.automatic_payment_flag" name="check-button" switch>{{ $t('subscriptions.automatic_payment_flag') }}</b-form-checkbox>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card card-custom">
                <div class="card-body row">
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('subscriptions.generate_invoice_every') }}<span class="text-danger">*</span></label>
                    <div class="input-group">
                      <input type="number" v-model="data.generate_invoice_every" class="form-control" :class="validation && validation.generate_invoice_every ? 'is-invalid' : ''"/>
                      <select name="" id="generate_interval" v-model="data.generate_interval" class="custom-select" :class="validation && validation.generate_interval ? 'is-invalid' : ''">
                        <option v-for="row in generate_invoice_every_list" :value="row.id" :key="row.id">{{ row.title }}</option>
                      </select>
                      <span v-if="validation && validation.generate_invoice_every" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.generate_invoice_every[0] }}
                                        </span>

                      <span v-if="validation && validation.generate_interval" class="fv-plugins-message-container invalid-feedback">
                                            {{ validation.generate_interval[0] }}
                                        </span>
                    </div>


                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('subscriptions.occurrences') }}</label>
                    <input type="number" v-model="data.occurrences" class="form-control" :class="validation && validation.occurrences ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.occurrences" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.occurrences[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('subscriptions.first_invoice_date') }}</label>
                    <input type="date" v-model="data.first_invoice_date" class="form-control" :class="validation && validation.first_invoice_date ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.first_invoice_date" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.first_invoice_date[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('subscriptions.issue_invoice_before') }}</label>
                    <div class="form-group row">
                      <div class="col-sm-10">
                        <input type="number" v-model="data.issue_invoice_before" class="form-control" :class="validation && validation.issue_invoice_before ? 'is-invalid' : ''"/>
                        <span v-if="validation && validation.issue_invoice_before" class="fv-plugins-message-container invalid-feedback">
                                                {{ validation.issue_invoice_before[0] }}
                                            </span>
                      </div>
                      <span class="col-sm-2">{{ $t('subscriptions.day_s') }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('subscriptions.payment_terms') }}<span class="text-danger">*</span></label>
                    <div class="form-group row">
                      <div class="col-sm-10">
                        <input type="number" v-model="data.payment_terms" class="form-control" :class="validation && validation.payment_terms ? 'is-invalid' : ''"/>
                        <span v-if="validation && validation.payment_terms" class="fv-plugins-message-container invalid-feedback">
                                                {{ validation.payment_terms[0] }}
                                            </span>
                      </div>
                      <span class="col-sm-2">{{ $t('subscriptions.day_s') }}</span>
                    </div>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <label>{{ $t('subscriptions.adjustment') }}</label>
                    <input type="number" v-model="data.adjustment" class="form-control" :class="validation && validation.adjustment ? 'is-invalid' : ''"/>
                    <span v-if="validation && validation.adjustment" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.adjustment[0] }}
                                    </span>
                  </div>
                  <div class="col-lg-6 mb-5">
                    <b-form-checkbox size="lg" v-model="data.is_active" name="check-button" switch>{{ $t('subscriptions.is_active') }}</b-form-checkbox>
                  </div>
                </div>
              </div>
              <div class="card card-custom mt-10">
                <div class="card-body row">
                  <div class="col-lg-12 mb-5">
                    <label>{{ $t('subscriptions.notes') }}</label>
                    <textarea name="" id="notes" v-model="data.notes" class="form-control" :class="validation && validation.notes ? 'is-invalid' : ''"></textarea>
                    <span v-if="validation && validation.notes" class="fv-plugins-message-container invalid-feedback">
                                        {{ validation.notes[0] }}
                                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="row mt-5">
            <div class="col-md-12">
              <div class="d-flex justify-content-between mt-2 p-2 bg-FFB803">
                <h6 class="my-auto text-white">{{ $t('subscriptions.items') }}</h6>
                <button type="button" class="btn btn-primary btn-sm" @click="addItemRowToList">{{ $t('add_more') }}</button>
              </div>
            </div>
            <div class="col-md-12">
              <div class="table-responsive bg-white">
                <table class="table table-row-bordered table-custom-padding" @keyup.alt.enter="addItemRowToList" @keyup.alt.46="removeItemRowFromList">
                  <thead>
                  <tr>
                    <th width="250px">{{ $t('items.item_details') }}</th>
                    <th width="300px">
                      <div class="d-flex justify-content-between">
                        <span>{{ $t('subscriptions.item') }}</span>
                        <a href="javascript:;" class="btn btn-primary btn-sm p-1" @click="showItemModal">
                          <b-icon icon="plus"></b-icon>
                        </a>
                      </div>
                    </th>
                    <!--                                            <th>{{$t('subscriptions.description')}}</th>-->
                    <th>{{ $t('subscriptions.unit_price') }}</th>
                    <th>{{ $t('subscriptions.qty') }}</th>
                    <!--                                            <th>{{$t('subscriptions.unit')}}</th>-->
                    <th>{{ $t('subscriptions.tax_1') }}</th>
                    <!--                                            <th>{{$t('subscriptions.tax_1_value')}}</th>-->
                    <th>{{ $t('subscriptions.tax_2') }}</th>
                    <!--                                            <th>{{$t('subscriptions.tax_2_value')}}</th>-->
                    <th>{{ $t('subscriptions.subtotal') }}</th>
                    <th></th>
                  </tr>

                  </thead>
                  <tbody>
                  <tr v-for="(row, index) in items_list" :key="index">
                    <td>
                      <a :id="'popover-target-'+index" href="javascript:;" v-if="row.item" class="d-flex cursor-pointer">
                        <img :src="row.item.image_url" style="width: 70px; height: 70px;" alt="">
                        <div class="pl-2 pr-2 text-dark">
                          <p class="mb-1" v-if="row.item.sku_code">{{ $t('items.sku_code') }}: {{ row.item.sku_code }}</p>
                          <p class="mb-1" v-if="row.item.name">{{ $t('items.name') }}: {{ row.item.name }}</p>
                          <p class="mb-1" v-if="row.item.sale_price">{{ $t('items.sale_price') }}: {{ row.item.sale_price }}</p>
                          <p class="mb-1" v-if="row.item.purchase_price">{{ $t('items.purchase_price') }}: {{ row.item.purchase_price }}</p>
                          <p class="mb-1" v-if="row.item.sum_available_qty">{{ $t('items.sum_available_qty') }}: {{ row.item.sum_available_qty }}</p>
                        </div>
                      </a>
                    </td>
                    <td>
                      <div class="d-flex search-item-group" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('subscriptions.item')">
                        <select name="" id="" v-model="row.f_by" class="custom-select" style="width: 100px;">
                          <option value="name">{{ $t('items.name') }}</option>
                          <option value="sku_code">{{ $t('items.sku_code') }}</option>
                        </select>
                        <multiselect v-model="row.item"
                                     :placeholder="$t('subscriptions.item')"
                                     :label="row.f_by? row.f_by:'name'"
                                     track-by="id"
                                     :options="items"
                                     :multiple="false"
                                     @input="selectItem(index)"
                                     :taggable="false"
                                     :show-labels="false"
                                     :show-no-options="false"
                                     :show-no-results="false"
                                     :internal-search="false"
                                     @search-change="getItems(row.f_by, $event)">
                        </multiselect>
                      </div>

                    </td>
                    <!--                                            <td><input v-model="row.description" type="text" class="form-control"></td>-->
                    <td><input v-model="row.unit_price" @input="setValue(index)" type="number" class="form-control"></td>
                    <td><input v-model="row.qty" @input="setValue(index)" type="number" class="form-control"></td>
                    <!--                                            <td>-->
                    <!--                                                <multiselect v-model="row.unit"-->
                    <!--                                                             :placeholder="$t('subscriptions.unit')"-->
                    <!--                                                             label="name"-->
                    <!--                                                             track-by="id"-->
                    <!--                                                             :options="units"-->
                    <!--                                                             :multiple="false"-->
                    <!--                                                             :taggable="false">-->
                    <!--                                                </multiselect>-->
                    <!--                                            </td>-->
                    <td>
                      <multiselect v-model="row.tax_1" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('subscriptions.tax')"
                                   :placeholder="$t('subscriptions.tax')"
                                   label="name"
                                   track-by="id"
                                   :options="taxes"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   @search-change="getTaxes($event)">
                      </multiselect>
                    </td>
                    <td>
                      <multiselect v-model="row.tax_2" v-b-tooltip.hover.leftbottom :title="$t('type_at_least_three_letters') + ' ' + $t('subscriptions.tax')"
                                   :placeholder="$t('subscriptions.tax')"
                                   label="name"
                                   track-by="id"
                                   :options="taxes"
                                   :multiple="false"
                                   :taggable="false"
                                   :show-labels="false"
                                   :show-no-options="false"
                                   :show-no-results="false"
                                   @search-change="getTaxes($event)">
                      </multiselect>
                    </td>
                    <!--                                            <td><input v-model="row.tax_2_value" type="number" class="form-control"></td>-->
                    <td>{{ row.subtotal }}</td>
                    <td>
                      <v-icon style="color: #dc3545;" small v-if="items_list.length > 1" @click="removeItemRowFromList(index)">mdi-delete</v-icon>
                    </td>
                  </tr>
                  </tbody>
                  <tfoot>
                  <tr>
                    <td colspan="5"></td>
                    <th>{{ $t('subscriptions.total_subtotal') }}</th>
                    <th><span>{{ data.subtotal }}</span></th>
                    <th></th>
                  </tr>
                  <tr>
                    <td class="border-0" colspan="5"></td>
                    <th>{{ $t('subscriptions.total_tax') }}(%)</th>
                    <th><span>{{ data.total_tax }}</span></th>
                    <th></th>
                  </tr>
                  <tr>
                    <td class="border-0" colspan="5"></td>
                    <th>{{ $t('subscriptions.total') }}</th>
                    <th><span>{{ data.total_after_tax }}</span></th>
                    <th></th>
                  </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </div>
        </b-tab>
        <b-tab :title="$t('subscriptions.attachments')" :disabled="!idEditing" @click="toggleTab('attachments')">

          <div class="card card-custom">
            <div class="card-body">
              <attachments :purchase-subscription-id="idEditing"></attachments>
            </div>
          </div>

        </b-tab>
        <b-tab :title="$t('activity_log.activity_log')" @click="toggleTab('activity_log')">
          <div class="card card-custom">
            <div class="card-body row">
              <activity-log :supplier-id="idEditing"></activity-log>
            </div>
          </div>
        </b-tab>
      </b-tabs>


      <div class="pl-0 pr-0">
        <div class="row">
          <div class="col-lg-6 mt-10">
            <button type="reset" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
            <button type="reset" class="btn btn-secondary">{{ $t('cancel') }}</button>
          </div>
        </div>
      </div>
    </div>

    <!--end::customer-->
    <b-modal ref="modal" size="lg" hide-footer :title="$t('customers.add_new_customer')">
      <customer-form @hide-modal="hideModal()" @handling-data="getDataAfterCreateNewCustomer"></customer-form>
    </b-modal>
    <b-modal ref="modalItem" size="lg" hide-footer :title="$t('items.add_new_item')">
      <item-form @hide-modal="hideItemModal()" @handling-data="getDataAfterCreateNewItem"></item-form>
    </b-modal>
  </div>
</template>
<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import Attachments from "./Attachments";
import ItemForm from "@/view/content/forms/ItemForm";
import CustomerForm from "@/view/content/forms/CustomerForm";
import ActivityLog from "./ActivityLog";
import {mapGetters, mapState} from "vuex";

export default {
  name: "form-subscriptions",
  components: {'attachments': Attachments, 'item-form': ItemForm, 'customer-form': CustomerForm, 'activity-log': ActivityLog},
  data() {
    return {
      mainRoute: 'purchases/subscriptions',
      mainRouteDependency: 'base/dependency',


      data: {
        name: null,
        generate_invoice_every: null,
        generate_interval: 1,
        occurrences: null,
        first_invoice_date: null,
        issue_invoice_before: null,
        payment_terms: null,
        customer_id: null,
        send_copy_flag: false,
        display_dates_flag: false,
        automatic_payment_flag: false,
        is_active: true,
        discount_type: 1,
        discount_value: null,
        adjustment: null,
        shipping_details: 1,
        shipping_fees: null,
        notes: null,
        total_tax: 0,
        total_after_tax: 0,
        subtotal: 0,

      },

      idEditing: this.$route.params.id ? this.$route.params.id : null,
      isEditing: false,

      customers: [],
      customer: null,
      generate_invoice_every_list: [
        // {id: 1, name: this.$t('day')},
        // {id: 2, name: this.$t('week')},
        // {id: 3, name: this.$t('month')},
        // {id: 4, name: this.$t('year')},
      ],


      items: [],
      taxes: [],
      units: [],
      shipping_details_list: [],
      default_unit: null,

      validation: null,
      tax_default: null,
      items_list_form: {id: null, item: null, description: null, unit_price: null, qty: null, unit: null, tax_1: null, tax_1_value: null, tax_2: null, tax_2_value: null, subtotal: null},
      items_list: [],
      discount_types: [
        {id: 1, name: this.$t('discount_types.none')},
        {id: 2, name: this.$t('discount_types.percentage')},
        {id: 3, name: this.$t('discount_types.value')},
      ],
      tab_name: 'basic_information',
    };
  },
  computed: {
    ...mapState({user_personal_info: state => state.profile.user_personal_info}),
    ...mapGetters(["currentUserPersonalInfo"]),

  },
  watch: {
    items_list: {
      handler(val) {
        if (val.length > 0) {

          this.data.subtotal = parseFloat(this.$_.sumBy(this.items_list, 'subtotal'));

          this.data.total_tax = this.$_.sumBy(this.items_list, (row) => {
            let _sub_total = (row.qty ? parseFloat(row.qty) : 0) * (row.unit_price ? parseFloat(row.unit_price) : 0);
            return row.tax_1 ? _sub_total * (parseFloat(row.tax_1.value_rate)/100) : 0;
          });
          this.data.total_tax = this.data.total_tax ? parseFloat(this.data.total_tax) : 0;

          this.data.total_after_tax = (this.data.subtotal + this.data.total_tax);

          this.data.subtotal = this.data.subtotal ? this.data.subtotal.toFixed(2) : 0;
          this.data.total_tax = this.data.total_tax ? this.data.total_tax.toFixed(2) : 0;
          this.data.total_after_tax = this.data.total_after_tax ? this.data.total_after_tax.toFixed(2) : 0;
        }
      },
      deep: true
    },

    '$route'(val) {
      if (val.params.id) {
        this.idEditing = val.params.id;
        this.getData();
      }
    },
    customer: function (val) {
      if (val) {
        this.data.customer_id = val.id;
      }
    },
  },
  methods: {
    save() {
      if (this.isEditing) {
        this.update();
      } else {
        this.create();
      }
    },

    create() {
      // this.data.status = this.data.status ? 1 : 0;
      let _items_list = [...this.items_list];
      _items_list = _items_list.filter((row) => (parseFloat(row.qty) > 0 && parseFloat(row.unit_price) > 0));

      ApiService.post(`${this.mainRoute}`, {
        items_list: _items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$successAlert(response.data.message);

            this.$router.push({name: 'subscriptions.edit', params: {id: response.data.data.id}});
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },


    update() {
      // this.data.status = this.data.status ? 1 : 0;
      let _items_list = [...this.items_list];
      _items_list = _items_list.filter((row) => (parseFloat(row.qty) > 0 && parseFloat(row.unit_price) > 0));

      ApiService.put(`${this.mainRoute}/${this.$route.params.id}`, {
        items_list: _items_list,
        ...this.data,
      })
          .then((response) => {
            this.validation = null;
            this.$router.push({name: 'subscriptions.index'});
            this.$successAlert(response.data.message);
          })
          .catch((error) => {
            this.$errorAlert(error);
            this.validation = error.response ? error.response.data.errors : null;
          });
    },

    async getData() {
      ApiService.get(`${this.mainRoute}/${this.$route.params.id}`).then((response) => {
        this.isEditing = true;
        this.data.name = response.data.data.name;
        this.data.generate_invoice_every = response.data.data.generate_invoice_every;
        this.data.generate_interval = response.data.data.generate_interval;
        this.data.occurrences = response.data.data.occurrences;
        this.data.first_invoice_date = response.data.data.first_invoice_date;
        this.data.issue_invoice_before = response.data.data.issue_invoice_before;
        this.data.payment_terms = response.data.data.payment_terms;
        this.data.customer_id = response.data.data.customer_id;
        this.customer = response.data.data.customer;
        this.data.send_copy_flag = response.data.data.send_copy_flag;
        this.data.display_dates_flag = response.data.data.display_dates_flag;
        this.data.automatic_payment_flag = response.data.data.automatic_payment_flag;
        this.data.is_active = response.data.data.is_active;
        this.data.discount_type = response.data.data.discount_type;
        this.data.discount_value = response.data.data.discount_value;
        this.data.adjustment = response.data.data.adjustment;
        this.data.shipping_details = response.data.data.shipping_details;
        this.data.shipping_fees = response.data.data.shipping_fees;
        this.data.notes = response.data.data.notes;

        this.data.total_tax = response.data.data.total_tax;
        this.data.total_after_tax = response.data.data.total_after_tax;
        this.data.subtotal = response.data.data.subtotal;

        this.items_list = response.data.data.items_list;

        if (response.data.data.items_list && response.data.data.items_list.length <= 0) {
          this.addItemRowToList();
        }
      });
    },

    async getCustomers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/customers", {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
    },

    getItems(f_by = null, filter = null) {
      let _f_by = f_by ? f_by : 'name';
      if (filter && filter.length >= 3 && _f_by)
        ApiService.get(this.mainRouteDependency + "/items", {params: {[_f_by]: filter, 'item_type': 1}}).then((response) => {
          this.items = response.data.data;
        });
    },
    getTaxes(filter) {
      if (filter && filter.length >= 3) {
        ApiService.get(`${this.mainRouteDependency}/taxes?type=3`, {params: {filter: filter}}).then((response) => {
          this.taxes = response.data.data;
        });
      } else {
        this.taxes = [];
      }
    },


    async getUnits() {
      await ApiService.get(this.mainRouteDependency + "/units").then((response) => {
        this.units = response.data.data;
      });
    },
    getShippingDetails() {
      ApiService.get(this.mainRouteDependency + "/shipping_details").then((response) => {
        this.shipping_details_list = response.data.data;
      });
    },
    getGenerateInvoiceEveryList() {
      ApiService.get(this.mainRouteDependency + "/interval").then((response) => {
        this.generate_invoice_every_list = response.data.data;
      });
    },

    loadOptions() {
    },


    addItemRowToList() {
      this.items_list_form.tax_1 = this.tax_default;
      this.items_list_form.tax_2 = this.tax_default;
      this.items_list_form.unit = this.default_unit;
      this.items_list.unshift(this.items_list_form);
      this.items_list_form = {id: null, item: null, description: null, unit_price: null, qty: null, unit: null, tax_1: null, tax_1_value: null, tax_2: null, tax_2_value: null, subtotal: null};
    },
    removeItemRowFromList(index = 0) {
      if (this.items_list.length > 1) {
        this.items_list.splice(index, 1);
      }
    },

    selectItem(index) {
      this.items_list[index].unit_price = this.items_list[index].item ? this.items_list[index].item.purchase_price : 0;
    },
    setValue(index) {
      this.items_list[index].subtotal = (this.items_list[index].unit_price ? this.items_list[index].unit_price : 0) * (this.items_list[index].qty ? this.items_list[index].qty : 0);
    },
    showModal() {
      this.$refs['modal'].show()
    },
    hideModal() {
      this.$refs['modal'].hide();
    },
    showItemModal() {
      this.$refs['modalItem'].show()
    },
    hideItemModal() {
      this.$refs['modalItem'].hide();
    },
    getDataAfterCreateNewCustomer(object) {
      let promise = this.getCustomers();
      Promise.all([promise]).then(() => {
        this.data.customer_id = object.id;
      })
    },
    getDataAfterCreateNewItem(object) {
      let promise = this.getItems();
      Promise.all([promise]).then(() => {
        if (object) {
          this.items_list[0].item = object;
          this.items_list[0].unit_price = object.purchase_price;
          this.items_list[0].tax_1 = (object.taxes && object.taxes[0] && object.taxes[0].tax) ? object.taxes[0].tax : null;
          this.items_list[0].tax_2 = (object.taxes && object.taxes[0] && object.taxes[0].tax) ? object.taxes[0].tax : null;
        }
      })
    },
    toggleTab(tab_name) {
      this.tab_name = tab_name
    },


    async defaultDataForUser() {
      await ApiService.get(this.mainRouteDependency + "/default_data_for_user").then((response) => {
        this.default_unit = response.data.data.unit;
        this.tax_default = response.data.data.tax;
      });
    }
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.subscriptions_management"), route: '/purchases/subscriptions'}, {title: (this.idEditing ? this.$t('update') : this.$t('create'))}]);


    // this.getTaxes();
    this.getItems();
    this.getUnits();
    this.getShippingDetails();
    this.getGenerateInvoiceEveryList();

    if (this.idEditing) {
      this.getData();
    } else {
      let promise = this.defaultDataForUser();
      Promise.all([promise]).then(() => {
        if (this.items_list.length <= 0) {
          this.addItemRowToList();
        }
      });
      this.data.first_invoice_date = new Date().toISOString().slice(0, 10);
    }
    // })


  },
};
</script>